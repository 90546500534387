import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/banner/Banner.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/banner" storybook="https://workday.github.io/canvas-kit/?path=/story/components-indicators-banner--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=30877-34306&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fe8acc0f452c51966f74103c1061564a/536c7/component-anatomy-banner.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "22.972972972972975%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of an alert and error Banner in its default state.",
              "title": "Image of an alert and error Banner in its default state.",
              "src": "/static/fe8acc0f452c51966f74103c1061564a/50383/component-anatomy-banner.png",
              "srcSet": ["/static/fe8acc0f452c51966f74103c1061564a/1efb2/component-anatomy-banner.png 370w", "/static/fe8acc0f452c51966f74103c1061564a/50383/component-anatomy-banner.png 740w", "/static/fe8acc0f452c51966f74103c1061564a/536c7/component-anatomy-banner.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Icon:`}</strong>{` Supplementary visual indicator used to distinguish between error and alert Banners.`}</li>
        <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Total count of all errors and/or alerts on a page.`}</li>
        <li parentName="ol"><strong parentName="li">{`Action Text (Conditional):`}</strong>{` Link text that indicates the Banner is actionable. Activating the
link opens a list of all errors and alerts on a page. Action text is only available in the full
Banner variant and has a default value of View All.`}</li>
        <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Colored container that houses the error or alert icon, message, and action text.
The container can be full or sticky, in which the Banner is to be displayed along the right edge
of the page.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`Banners consist of errors and alerts:`}</p>
      <ul>
        <li parentName="ul">{`Typically appearing in response to user action, errors prevent the user from moving forward in
their process until the error is corrected. Common error triggers include failing to enter a value
for required fields, entering values in a form that are incompatible, or when user input is not
understood.`}</li>
        <li parentName="ul">{`Alerts convey information the user should be aware of to help prevent a future error, but allow
the user to proceed without addressing the alert.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Banners to notify users of missteps that happen within a workflow and describe how the user
can take appropriate action to resolve them.`}</li>
        <li parentName="ul">{`Banners should bring a user’s attention to problems or mistakes either before they happen, or
before the user can move on.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Ideally, a design won’t create a scenario that causes the need for a Banner. If you can avoid
these scenarios by mentioning information that will help in hint text, or by simplifying your
design, always do so.`}</li>
      </ul>
      <h3 {...{
        "id": "dos-and-donts"
      }}>{`Do's and Don'ts`}</h3>
      <SideBySide mdxType="SideBySide">
        <Suggestion guidance="Combine error and alert messages into a single error Banner." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "600px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/6107f3303c322d47cf31f5cf828a9e2e/b4294/banner-do.jpg",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.21621621621621%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHdjaCljX//xAAZEAACAwEAAAAAAAAAAAAAAAAAAgEQEkH/2gAIAQEAAQUCOZkRtX//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAARAAMmH/2gAIAQEABj8CVzDhf//EABoQAAIDAQEAAAAAAAAAAAAAAAABESFREPH/2gAIAQEAAT8hkeKenmIa9YoI5//aAAwDAQACAAMAAAAQzP8A/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARIfD/2gAIAQMBAT8QEdlnwX//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QRHGy/8QAHRABAAICAgMAAAAAAAAAAAAAAQARIUExUXGh8P/aAAgBAQABPxAyTXUCoVxgaln3+oJuRNPM8mBW7n//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Combine error and alert messages into a single error Banner.",
                  "title": "Combine error and alert messages into a single error Banner.",
                  "src": "/static/6107f3303c322d47cf31f5cf828a9e2e/b4294/banner-do.jpg",
                  "srcSet": ["/static/6107f3303c322d47cf31f5cf828a9e2e/f4308/banner-do.jpg 370w", "/static/6107f3303c322d47cf31f5cf828a9e2e/b4294/banner-do.jpg 600w"],
                  "sizes": "(max-width: 600px) 100vw, 600px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="error" guidance="Do not use separate error and alert Banners if there are both errors and alerts on the same page." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "600px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/c519a3f2c552904683382a1f37279b44/b4294/banner-dont.jpg",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.21621621621621%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHdjawJ4v8A/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAISEEH/2gAIAQEAAQUCOS4jVv8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAEDAQE/Aa0//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AbGP/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAM2Gh/9oACAEBAAY/AlZyHC//xAAaEAADAQADAAAAAAAAAAAAAAAAAREhEFHw/9oACAEBAAE/IaNOpj7PRBr5mBOP/9oADAMBAAIAAwAAABCM7//EABkRAQACAwAAAAAAAAAAAAAAAAEAESFRkf/aAAgBAwEBPxADkltHCf/EABgRAQADAQAAAAAAAAAAAAAAAAEAEVGR/9oACAECAQE/EEGmV16z/8QAHBABAAICAwEAAAAAAAAAAAAAAQARIUExUWFx/9oACAEBAAE/EDJNdRhMcopdT3SE3ImnmfTArdz/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Do not use separate error and alert Banners if there are both errors and alerts on the same page.",
                  "title": "Do not use separate error and alert Banners if there are both errors and alerts on the same page.",
                  "src": "/static/c519a3f2c552904683382a1f37279b44/b4294/banner-dont.jpg",
                  "srcSet": ["/static/c519a3f2c552904683382a1f37279b44/f4308/banner-dont.jpg 370w", "/static/c519a3f2c552904683382a1f37279b44/b4294/banner-dont.jpg 600w"],
                  "sizes": "(max-width: 600px) 100vw, 600px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-banners-impact-the-accessible-experience"
      }}>{`How Banners Impact the Accessible Experience`}</h3>
      <p>{`Banner components can introduce accessibility barriers to successfully and accurately completing
forms when accessibility is neglected. Users must be able to navigate to, and activate, a Banner
component without using a mouse or trackpad. Banners must be keyboard focusable in the order they
are appearing visually on screen. (Logical, left to right, top to bottom for left to right
languages.)`}</p>
      <p>{`When Banners appear on form submission, or from another explicit action by the user, setting
keyboard focus onto the Banner can be a helpful way of guiding the interaction.`}</p>
      <p>{`When Banners are generated by the system, or when users focus out of form fields, then it is not
appropriate to move keyboard focus in these scenarios. This can be very disruptive, and may block
users from completing their task.`}</p>
      <p>{`Refer to`}</p>
      <LegacyPatternLink href="/patterns/notifications/#tab=guidance&heading=accessibility-considerations" mdxType="LegacyPatternLink">Accessibility
Considerations in Notification Patterns</LegacyPatternLink> for more information.
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Banner must have a focus indicator that is highly visible against the background and against
the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more
information.`}</p>
      <p>{`Keyboard focus must be set to the Banner component when it appears after a form submission, but do
not force any focus movements on other events such as `}<inlineCode parentName="p">{`blur`}</inlineCode>{` or `}<inlineCode parentName="p">{`change`}</inlineCode>{`.`}</p>
      <p>{`Banners must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus the Banner element`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: activate the Banner element`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Banners must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The text displayed inside the Banner`}</li>
        <li parentName="ul">{`The Banner is either an “alert” or an “error”`}</li>
        <li parentName="ul">{`The Banner is an interactive “button” element`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Specify the Banner’s error type: Alert or Error`}</li>
        <li parentName="ul">{`Specify the keyboard focus order for the Banner in context of the page`}</li>
        <li parentName="ul">{`Write the text alternative for the Icon when it conveys information to users. Read more about
`}<a parentName="li" {...{
            "href": "https://canvas.workdaydesign.com/accessibility/alt-text#what-is-non-text-content"
          }}>{`Non-Text Content`}</a></li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`When the Banner Icon requires text alternative, set ARIA `}<inlineCode parentName="li">{`role=”img”`}</inlineCode>{` and an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string
describing the Icon.`}</li>
        <li parentName="ul">{`Banner must be rendered in the DOM structure relative to where it is positioned visually on screen
for the best keyboard focus accessibility.`}</li>
        <li parentName="ul">{`When a Banner is generated from a user action, set keyboard focus to the Banner.`}</li>
        <li parentName="ul">{`When a Banner is generated by the system, avoid moving the keyboard focus away from users’
context. Instead, use an `}<inlineCode parentName="li">{`aria-live`}</inlineCode>{` region in the DOM to send the Banner message for screen
readers to announce in real-time.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`When writing error or alert messages, refer to the
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/error-alert-messages"
          }}>{`Errors and Alerts`}</a>{` section of the Content Style Guide.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?type=design&node-id=10328-16427&mode=design&t=5gS8nxwCmiayXYM6-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c7bed1763cbc284ad36794f70202e725/536c7/banner-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "34.32432432432432%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABBUlEQVR42o2Rj26CQAzGff+n2XssGxCn2YZRUTgGiC6IzLtr+62gWeK/ZE2+tL1cfvnajnAdck7bFmR2p1oE/43RNUzYabLgTQVZZfpoIXRQtRC/P6mvXQPuOoilB0Bi8CQF7XZw8RP2xSuyWYCvdYR0/gLbrfRPDjijUFWbwM8T8GdxNiN3gFMDKko0y2eUWQSTRCjSMbJliDqf4rv6GNRsYlgFCh/+VnTfYVyCmy2q9RsyhZXZGOkiQJ6ECg3g2xWoW4N+UnAvHf/xDnvgew6qK5SLEKWZoKlnCg4VGKHSXqzRleq4RzMAye0vL3lzFA0WBYsHsweRHWoZegfvjxdiphuHv7B5HjsCluhzAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of a warning banner",
                "title": "The basic elements of a warning banner",
                "src": "/static/c7bed1763cbc284ad36794f70202e725/50383/banner-anatomy.png",
                "srcSet": ["/static/c7bed1763cbc284ad36794f70202e725/1efb2/banner-anatomy.png 370w", "/static/c7bed1763cbc284ad36794f70202e725/50383/banner-anatomy.png 740w", "/static/c7bed1763cbc284ad36794f70202e725/536c7/banner-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8b164b16997f8365ada9517dcad1a0ed/536c7/banner-anatomy2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "48.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABOUlEQVR42o2SbU/CMBSF+f+f/eA/0sRIDFERHI7hNsY6uvLWbay9x9sBKiNMm5zcrlmf9pzeHtqDjkXuYOfq+EH47+hd8IyBrTRMLECTuVsAyhKoKtapVqCyANV1B7Bm0GsIu1xjc3sD3X/AJoqxCqasAGrqQ/F8PQuQBz6sELA73QHcG5i+DxMKJHf3mA2eIN3GZYY6EwcJ8T0nuYQtig4g50QrzafuUKYLFEnSbITKgVz+VKeVAqUCxotgx8lZzi3L/EMiod49RKMhtuFnA6IT6JeKMMR+wA4e/StAt8C27Wbb5DV7ecY2Cg+3YXttoIvCav33K7tc5McE8duweYQzqycpCcqyJm+K87N2uwCaQiPzPCTjEeYsy7ejtnJ+kDRFHWWgxbob2PQh95erToZ77pqstReWvwD51wfvYVjljgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of an error banner",
                "title": "The basic elements of an error banner",
                "src": "/static/8b164b16997f8365ada9517dcad1a0ed/50383/banner-anatomy2.png",
                "srcSet": ["/static/8b164b16997f8365ada9517dcad1a0ed/1efb2/banner-anatomy2.png 370w", "/static/8b164b16997f8365ada9517dcad1a0ed/50383/banner-anatomy2.png 740w", "/static/8b164b16997f8365ada9517dcad1a0ed/536c7/banner-anatomy2.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Icon:`}</strong>{` Added visual to support the overall message and stress the severity. These icons are
specific to the type of banner and are not configurable.`}</li>
          <li parentName="ol"><strong parentName="li">{`Title:`}</strong>{` Summarized description of the main purpose of the banner.`}</li>
          <li parentName="ol"><strong parentName="li">{`Message:`}</strong>{` Main content describing the purpose of the banner in more detail. This message
should be short and concise.`}</li>
          <li parentName="ol"><strong parentName="li">{`Buttons:`}</strong>{` Any Primary or Secondary actions of the banner.`}</li>
          <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Houses all banner elements. Color may vary based on type of messaging.`}</li>
          <li parentName="ol"><strong parentName="li">{`Input Labels:`}</strong>{` Highlights the specific location of an error/warning using the title of the
input in question. The number of labels displayed at once is limited due to space.`}</li>
          <li parentName="ol"><strong parentName="li">{`Counter:`}</strong>{` Shows total number of errors on the page, including all hidden errors. Counter hides
when the number of errors is small enough to fit all within the banner.`}</li>
          <li parentName="ol"><strong parentName="li">{`Divider:`}</strong>{` Visually helps separate the content from the upper half and the lower half.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To communicate errors or warnings about a task. Note that banners do not disappear unless the user
resolves the required action(s).`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-consider-something-else"
        }}>{`When to Consider Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If information is critical and requires user action or decision, consider using an
`}<a parentName="li" {...{
              "href": "/components/popups/alert-dialog"
            }}>{`Alert Dialog`}</a>{`. Alert Dialogs are reserved for messages of the
highest priority; they command user action by blocking any other interactions.`}</li>
          <li parentName="ul">{`If communicating updates about the progress of an application, consider using Snackbars; they do
not require user action.`}</li>
          <li parentName="ul">{`For `}<a parentName="li" {...{
              "href": "/styles/assets/empty-states"
            }}>{`Empty States`}</a>{`, use the full page to communicate the status of the
feature or to help educate users about it’s purpose . Empty states should include a message,
graphic and any appropriate action(s).`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Emphasis`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Purpose`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Interaction`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Snackbars`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Communicates updates about the progress of an application.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Temporary. Dismisses automatically after a short period of time but can also be manually dismissed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Banners`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Communicates errors or warnings about a task.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Top aligned. Remains persistent until the user resolves issue(s).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Dialogs`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`High`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Communicates critical information that requires an action or decision.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Blocks all other interaction until a decision has been made.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "behaviors"
        }}>{`Behaviors`}</h2>
        <p>{`Specific guidance on how the component behaves. Include screenshots when appropriate.`}</p>
        <h3 {...{
          "id": "positioning"
        }}>{`Positioning`}</h3>
        <p>{`Banners are fixed at the top of the page directly below the Top App Bar, but live at the same level
as the Top App Bar. They are persistent and until user interaction resolves the reason for the
banner to be there.`}</p>
        <h3 {...{
          "id": "modality"
        }}>{`Modality`}</h3>
        <p>{`Banners are non-modal messages, meaning they do not block the user from interacting with the rest of
the page content, unlike Alert Dialogs. So users can continue to scroll or interact with any other
element on the page.`}</p>
        <h3 {...{
          "id": "multiple-warnings-or-alerts"
        }}>{`Multiple Warnings or Alerts`}</h3>
        <p>{`Only one banner may be displayed at a time. Multiple warnings or errors should be grouped under a
single error banner. If multiple, unrelated, errors or warnings occur, only one banner may be
displayed at a time, display any subsequent banners after resolving the current one.`}</p>
        <h2 id="ios-examples">Examples</h2>
        <h3 {...{
          "id": "single-error"
        }}>{`Single Error`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e0c6d30754a663e7e33aa164dcfd785e/536c7/banner-single-error-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "48.10810810810811%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB+0lEQVR42oWSXU8aQRSG/TuN3PoPetmfYLjrD+lVb1rTG2qs0bYCSgWlJqQx2KQKjat8KR8VZKkt6LKwLPKxwLqCPJ0ladqmGE/yZjIz5zzzzpyZqTWa2FI1nWa7S75YQjqOc3gUI5E6JZM7I539RlqM9vwolpjovHQxybfrfjNszfwNswuWlt4QDAYJh8M4nU5mZ2eZm5vD4XAwPz9PKBTC5/PhcrlInqS57hj/QCdApabRMy3W3F5kWcayLOyoVCocRCIcShKRaJRyuYxhGOi6TjKRYDOwRac3oFpv/A80Bjd41zfIForc3o0Zj0ZMi26nza04MJM+ZSv4kZbRvwfYN/H4A5R2gow+79JWVTS5SPPHBUO9wbh1PdHddRPMAaVUEr9w2L4XKBx6PvgpfArR/3qA8r1EJZehep7HUC4ZqAr96hUjXRPAPnIy/hDQYn3DRymb4UarYapVMeqYItkU+6Zap6cowq1weGMhp04edri8ssrW3hfCsVNyZzno1MQVLxnbaimM23VGjZ/Q0yjGowS2twVwSlPsBfsNn798xcLeJu93V5Gy+6KwwG19h1Hdx1B1M7xaZFjzQdtP8XgBr3dNNGWAOs1hTzh0iT/45J2bF/63RHMSqueZAEagsQKpxyA94i7/VLzhPoXYMq8XF2l1e+If/gH+As0ZwZ89DwbDAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A banner with a single error",
                "title": "A banner with a single error",
                "src": "/static/e0c6d30754a663e7e33aa164dcfd785e/50383/banner-single-error-iOS.png",
                "srcSet": ["/static/e0c6d30754a663e7e33aa164dcfd785e/1efb2/banner-single-error-iOS.png 370w", "/static/e0c6d30754a663e7e33aa164dcfd785e/50383/banner-single-error-iOS.png 740w", "/static/e0c6d30754a663e7e33aa164dcfd785e/536c7/banner-single-error-iOS.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When only a single error needs to be communicated to the user.`}</p>
        <h3 {...{
          "id": "single-warning"
        }}>{`Single Warning`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4846957502b04dffe34f535dda79382b/536c7/banner-single-warning-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "48.10810810810811%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB/UlEQVR42n2S204aQRyHfZ1Gb3mDXvYRDHd9j971pjW9ocQabStKsUjpBW1aatIKBkSUinJQWKkcZDkssLDIshz26yxp06NO8stkJvP/8s3Mf67WbGFFbii01B7ZnEQ0Fieyf8Bh4piTVIbkaZqkmK31/sHhLOdSYXbeqvvJsDL3O8wqWF5+ht/vJxgMYrfbmZ+fx2azsbCwwOLiIoFAAI/Hg8Ph4OhrknZX+wM6A17VGvR1g3XXJvl8HsMYYY1SqcRuKEwkGiUUDlMsFtE0DUVpcXR4xOttH93+gGq9+S9QGwxxv/Jwks5iTCaY0zGmafL36HVVRsaQk+QxPv9bOtr1zcANt4di5jOoCTqKhFJNIZeSTAZlTKM6y3R4JbBtpGwErzBUtVsMXe4tpOP36NUwdfmMeiVJTQC1dh69W0BXLzCHFQFUyGf28Pos4I2GBm7x2NJ5DL39jY6coVZO0W7k0HslrtVLAb0UQFkAVWG4/8PwliuvrK7hC39kJxUnltoTdnHacgJzIAlQQaTIuJ+DaZnc6Q7bb264srWhXes8fPyEpU9eXn5YI5YOgXHBRHnHuO5hIm8wqTgZ17aEoJdcbInNzXXxKQPk/xn2haFD9OC9Fy4eeZ8TSkWouh4wqu1CcxUSdyF6h2n2PuhfODtY4anTSafXF334C/gdlm/B08JHjVYAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A banner with a single warning",
                "title": "A banner with a single warning",
                "src": "/static/4846957502b04dffe34f535dda79382b/50383/banner-single-warning-iOS.png",
                "srcSet": ["/static/4846957502b04dffe34f535dda79382b/1efb2/banner-single-warning-iOS.png 370w", "/static/4846957502b04dffe34f535dda79382b/50383/banner-single-warning-iOS.png 740w", "/static/4846957502b04dffe34f535dda79382b/536c7/banner-single-warning-iOS.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When only a single warning needs to be communicated to the user.`}</p>
        <h3 {...{
          "id": "multi-error"
        }}>{`Multi-Error`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d0a0f2a28e94da16388b7fe57e81d549/536c7/banner-multi-error-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "80.27027027027026%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAACUUlEQVR42o1T227aQBDNh/GQ5h/TJFKAEjU4QCXaRuE5gjRJG0gfqqpVU+7YxsZXzMXgtU9ntiUqaqBZ6WjW3p0zZ+eyMxzZWMGwHGlvP9VRvbrGZfVK2tqHGwney+/rG3ys36/5rLCz2uimBccP8Pb9ORRFQbVWRblcxu7uLlKplMTeiz0oeQWVSgXp4zTenV/A9gLp+w8hRxroBl6f5hEEAeI4RpIk+PHwgLt6HY3GPZrNJmbzOeYEx3GQp8B9bbimco2QDxXlDB5dBhMul1guFjBVDaOhjoi+EQtQJITTCQqFAnrkY24i7Kk6iqU38IunCO9uMRmZGPe6mGgqgkEfPu0Z434Poa6hQK/ZTkiHRYo6rF3C+NyA2+1AEOnSNCQi2jNi28KCFD+LsJDPw1ZVuKxK14FgjIRyypYR+760Mwpwto3QtF20ewMcp9NYcq5ohWGI8XgM13XheZ60s9ns8axQLG0uysjx0Oz0cHh0hCiKpJNt21BJbbvdRqvVkpZJeUXREplMVopgMU8q7PRVvMrlpIqQqsvtsSArhMBqcSvx4n/7+y/xs92VYjYqPDg4hEPKDMOAaY6g60PqOZcUCQkmEiLGdDpF7uREitiqMJPNSmVSzUoVIBt9BSZ0KafFUun/jc196FgWwskUET2XIRiUVyZi8Kt/F6Uoe/dphbKxiZAqp33/hqk2AHwXiesgcSwJJMljLjm3XGX2Me1tjU1R7a9fELabiGk6BE1GrKsQ1Mg8js8mNP+MHl8KaRLgexCkTlgjxPS8v9VtI/wFTfWRa5y/R8UAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A banner with multiple errors",
                "title": "A banner with multiple errors",
                "src": "/static/d0a0f2a28e94da16388b7fe57e81d549/50383/banner-multi-error-iOS.png",
                "srcSet": ["/static/d0a0f2a28e94da16388b7fe57e81d549/1efb2/banner-multi-error-iOS.png 370w", "/static/d0a0f2a28e94da16388b7fe57e81d549/50383/banner-multi-error-iOS.png 740w", "/static/d0a0f2a28e94da16388b7fe57e81d549/536c7/banner-multi-error-iOS.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When multiple errors need to be communicated to the user. When errors and warnings exist together
they should be combined into this variation. The collapsed state is the default state when these
multi-error banners first appear.`}</p>
        <h3 {...{
          "id": "multi-error-expanded"
        }}>{`Multi-Error Expanded`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/560d1535b52f765f9056805c50ddab11/536c7/banner-multi-error-expanded-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "80.27027027027026%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAACXElEQVR42o1U227aQBDNh/FA84dRhCqikqhSwNhUom1UXluRNEkbwmMbhUsA4wu+YWMDxrfT2U2JRAu0lo527Z05c2Y8swe6aWONieXw9fZ7C82ra3xpXvH18usNB9vz9+sbfGu1N3zWOFhvNMOC4/l4//ECgiCgedlEvV5HPp9HLpfjOHx1CKEioNFooPSmhA8Xn2C7Pvf9i5BFGmsTnJcr8H0faZoiyzI8djq4a7Vwf99Gr9fDYrnEkuA4DioUWFb1DZUbhOxQEKpwyRiMMI4QrVYwFBWmriGOIvqegCIhnAcQRREj8jF2EY4UDVLtHTzpHOHdLXzDgDcaIlAV+GOZ7xlm8gihpkKkbPYT0qFEUdXmZ2jtFjwiSSwTkTFBZBqIGeg9tS2sJtr/EYoVqiFF95UxFqQMngtMqQTu9BlsP/OIkGzL5d2Ehj1FfzRGqViE2XlESEoQ+M8Ef4KIZ5S6RMFldbL9p5iOi95QRrFQgNntwOp1MR088fS2EbKUT09O8CQrXMxWhQM6fFsqwSCFys8fcIeDnYSstoWjI3QHIy5mu0I6fH18zNWpDw9IyTkjpI79gozwrFDnwQdjdb/Cs9MzrBYL3ocx9WBCvZfG8QvYtzSKsaDmF0Xp340t1WoIVyHYY1kWZFlGt9sjdNHv9/mEsGdFxFVJ4r27UyE7FMkopNFi07AgpWwMGYIg4CsjWhOKUo18qG3snZNCCsnINE3oOhnSpOhUK9fzkAF8tpMkpWpkfJ5FrpBStvekXKW6BPM5V5EkCRKqZfL7oliDXRyu63GFsrJZw19PVI2vTzjb0wAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "An expanded banner with multiple errors",
                "title": "An expanded banner with multiple errors",
                "src": "/static/560d1535b52f765f9056805c50ddab11/50383/banner-multi-error-expanded-iOS.png",
                "srcSet": ["/static/560d1535b52f765f9056805c50ddab11/1efb2/banner-multi-error-expanded-iOS.png 370w", "/static/560d1535b52f765f9056805c50ddab11/50383/banner-multi-error-expanded-iOS.png 740w", "/static/560d1535b52f765f9056805c50ddab11/536c7/banner-multi-error-expanded-iOS.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When a user taps on the “Show Errors” button the lower portion expands revealing, a space limited
view of, all the errors/warnings. A counter is provided to communicate the total amount of errors
preceded by the number of errors visible within the banner. Tapping on any input label within the
banner should automatically scroll the page to the input in question. When errors are resolved by
the user, the labels are dynamically removed from the section, with the Banner closing once all
errors have been resolved.`}</p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <p><strong parentName="p">{`Class name:`}</strong>{` `}<inlineCode parentName="p">{`Banner`}</inlineCode>{` `}<strong parentName="p">{`Module name:`}</strong>{` `}<inlineCode parentName="p">{`UIComponentsPlugin`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`    public init(
        featureData: FeatureMetricsData,
        title: String,
        message: String?,
        bannerType: BannerType,
        mainButton: BannerButtonItem? = nil,
        auxiliaryButton: BannerButtonItem? = nil,
        errorsList: Binding<[BannerButtonItem]> = Binding.constant([]),
        localizer: LocalizationAdapting,
        shouldShowBanner: Bool = true
    )
`}</code></pre>
        <h3 {...{
          "id": "parameters"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`featureData`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The feature name/context and the screen ID in which the component appears.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`title`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The title of the task we are using this banner for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`message`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This should describe the task's description. Message should be short and precise preferably up to two lines.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`bannerType`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Config that defines what type of banner to show ex: warning, error or multiError.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`mainButton`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of the Button that provides interaction for the user to act on. If only one Button is displayed, it should be this one.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`auxiliaryButton`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of the Button that provides interaction for the user to act on.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`errorsList`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The list that will be displayed in the Multi Error configuration, Only the first 3 errors will be displayed and the rest are hidden and shown as we click on the errors and resolve them.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`localizer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Provider of localized strings, conforming to `}<inlineCode parentName="td">{`LocalizationAdapting`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`shouldShowBanner`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This determines if the banner is shown/hidden. Instead of removing the view from the composition, if this boolean is updated to false, banner will be hidden with an animation.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Coming Soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4e54d3e528884560997979a4fba7c6f0/536c7/banner-content.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.45945945945946%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABjElEQVR42o1T2VLCQBDk///J8s0qRQEVVOTIvckeswkBRBK1nV2wSnmQPHTtbGqmM9M92ys04S80VLmCsRa2JIb10Ia/n6DQBqf1vd+XXBlQRfiQV9joMfJkDJk+QkT32G8SoC2A5oCvvQBZyTX/EnJHTLiNLkHRNYQjTB6QBgNUeoo1zbAyr1jbOdptjNKqDoT819oGMMUUtngGMax8gRYTvK9DNJsYDZN97lKfe5awLBVUMoIIuSv1gnjeR7K4RR6NAB4Tbc4j87nPmLD4n9BBaum1IRKoyhyWuJDj0ubHu4sPkFqdM4VA7GyjbrGlKUq9RE1L1m8Okk67AO1OoH1zyLqYYrwp78kFqqyPeDFEuhx4U8LZjR/b6edHbrKOGnKSkTME8wGMeILKxoyJN8atj4ud087ls4TiaEqlnqF4/3argDtKsK2W2NUh3urAx/t15Lvs1KFLouIJMY+ahSPWcIY0HPrukmCIaHHHmr56x4k6uFwoflbstCbtoYw6PrvD+RO7bXC5py5/A/GIf7qDRCTiAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Warning banners in multiple languages",
                "title": "Warning banners in multiple languages",
                "src": "/static/4e54d3e528884560997979a4fba7c6f0/50383/banner-content.png",
                "srcSet": ["/static/4e54d3e528884560997979a4fba7c6f0/1efb2/banner-content.png 370w", "/static/4e54d3e528884560997979a4fba7c6f0/50383/banner-content.png 740w", "/static/4e54d3e528884560997979a4fba7c6f0/536c7/banner-content.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ul>
          <li parentName="ul">{`Messages in banners should be short and concise, preferably up to 2 lines. Additional lines may be
needed to support other languages with longer characters.`}</li>
          <li parentName="ul">{`In titles, avoid apologies (i.e., sorry, please, etc…), or additional written level of emphasis
(e.g., warning).`}</li>
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/error-alert-messages"
            }}>{`Error and Alert Messages`}</a>{` in the UI Text Section of
Content Style Guide for additional language guidelines.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?type=design&node-id=10328-16427&mode=design&t=5gS8nxwCmiayXYM6-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c7bed1763cbc284ad36794f70202e725/536c7/banner-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "34.32432432432432%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABBUlEQVR42o2Rj26CQAzGff+n2XssGxCn2YZRUTgGiC6IzLtr+62gWeK/ZE2+tL1cfvnajnAdck7bFmR2p1oE/43RNUzYabLgTQVZZfpoIXRQtRC/P6mvXQPuOoilB0Bi8CQF7XZw8RP2xSuyWYCvdYR0/gLbrfRPDjijUFWbwM8T8GdxNiN3gFMDKko0y2eUWQSTRCjSMbJliDqf4rv6GNRsYlgFCh/+VnTfYVyCmy2q9RsyhZXZGOkiQJ6ECg3g2xWoW4N+UnAvHf/xDnvgew6qK5SLEKWZoKlnCg4VGKHSXqzRleq4RzMAye0vL3lzFA0WBYsHsweRHWoZegfvjxdiphuHv7B5HjsCluhzAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of a warning banner",
                "title": "The basic elements of a warning banner",
                "src": "/static/c7bed1763cbc284ad36794f70202e725/50383/banner-anatomy.png",
                "srcSet": ["/static/c7bed1763cbc284ad36794f70202e725/1efb2/banner-anatomy.png 370w", "/static/c7bed1763cbc284ad36794f70202e725/50383/banner-anatomy.png 740w", "/static/c7bed1763cbc284ad36794f70202e725/536c7/banner-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8b164b16997f8365ada9517dcad1a0ed/536c7/banner-anatomy2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "48.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABOUlEQVR42o2SbU/CMBSF+f+f/eA/0sRIDFERHI7hNsY6uvLWbay9x9sBKiNMm5zcrlmf9pzeHtqDjkXuYOfq+EH47+hd8IyBrTRMLECTuVsAyhKoKtapVqCyANV1B7Bm0GsIu1xjc3sD3X/AJoqxCqasAGrqQ/F8PQuQBz6sELA73QHcG5i+DxMKJHf3mA2eIN3GZYY6EwcJ8T0nuYQtig4g50QrzafuUKYLFEnSbITKgVz+VKeVAqUCxotgx8lZzi3L/EMiod49RKMhtuFnA6IT6JeKMMR+wA4e/StAt8C27Wbb5DV7ecY2Cg+3YXttoIvCav33K7tc5McE8duweYQzqycpCcqyJm+K87N2uwCaQiPzPCTjEeYsy7ejtnJ+kDRFHWWgxbob2PQh95erToZ77pqstReWvwD51wfvYVjljgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of an error banner",
                "title": "The basic elements of an error banner",
                "src": "/static/8b164b16997f8365ada9517dcad1a0ed/50383/banner-anatomy2.png",
                "srcSet": ["/static/8b164b16997f8365ada9517dcad1a0ed/1efb2/banner-anatomy2.png 370w", "/static/8b164b16997f8365ada9517dcad1a0ed/50383/banner-anatomy2.png 740w", "/static/8b164b16997f8365ada9517dcad1a0ed/536c7/banner-anatomy2.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Icon:`}</strong>{` Added visual to support the overall message and stress the severity. These icons are
specific to the type of banner and are not configurable.`}</li>
          <li parentName="ol"><strong parentName="li">{`Title:`}</strong>{` Summarized description of the main purpose of the banner.`}</li>
          <li parentName="ol"><strong parentName="li">{`Message:`}</strong>{` Main content describing the purpose of the banner in more detail. This message
should be short and concise.`}</li>
          <li parentName="ol"><strong parentName="li">{`Buttons:`}</strong>{` Any Primary or Secondary actions of the banner.`}</li>
          <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Houses all banner elements. Color may vary based on type of messaging.`}</li>
          <li parentName="ol"><strong parentName="li">{`Input Labels:`}</strong>{` Highlights the specific location of an error/warning using the title of the
input in question. The number of labels displayed at once is limited due to space.`}</li>
          <li parentName="ol"><strong parentName="li">{`Counter:`}</strong>{` Shows total number of errors on the page, including all hidden errors. Counter hides
when the number of errors is small enough to fit all within the banner.`}</li>
          <li parentName="ol"><strong parentName="li">{`Divider:`}</strong>{` Visually helps separate the content from the upper half and the lower half.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To communicate errors or warnings about a task. Note that banners do not disappear unless the user
resolves the required action(s).`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-consider-something-else-1"
        }}>{`When to Consider Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If information is critical and requires user action or decision, consider using an
`}<a parentName="li" {...{
              "href": "/components/popups/alert-dialog"
            }}>{`Alert Dialog`}</a>{`. Alert Dialogs are reserved for messages of the
highest priority; they command user action by blocking any other interactions.`}</li>
          <li parentName="ul">{`If communicating updates about the progress of an application, consider using Snackbars; they do
not require user action.`}</li>
          <li parentName="ul">{`For `}<a parentName="li" {...{
              "href": "/styles/assets/empty-states"
            }}>{`Empty States`}</a>{`, use the full page to communicate the status of the
feature or to help educate users about it’s purpose . Empty states should include a message,
graphic and any appropriate action(s).`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Emphasis`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Purpose`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Interaction`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Snackbars`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Communicates updates about the progress of an application.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Temporary. Dismisses automatically after a short period of time but can also be manually dismissed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Banners`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Communicates errors or warnings about a task.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Top aligned. Remains persistent until the user resolves issue(s).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Dialogs`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`High`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Communicates critical information that requires an action or decision.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Blocks all other interaction until a decision has been made.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "behaviors-1"
        }}>{`Behaviors`}</h2>
        <p>{`Specific guidance on how the component behaves. Include screenshots when appropriate.`}</p>
        <h3 {...{
          "id": "positioning-1"
        }}>{`Positioning`}</h3>
        <p>{`Banners are fixed at the top of the page directly below the Top App Bar, but live at the same level
as the Top App Bar. They are persistent and until user interaction resolves the reason for the
banner to be there.`}</p>
        <h3 {...{
          "id": "modality-1"
        }}>{`Modality`}</h3>
        <p>{`Banners are non-modal messages, meaning they do not block the user from interacting with the rest of
the page content, unlike Alert Dialogs. So users can continue to scroll or interact with any other
element on the page.`}</p>
        <h3 {...{
          "id": "multiple-warnings-or-alerts-1"
        }}>{`Multiple Warnings or Alerts`}</h3>
        <p>{`Only one banner may be displayed at a time. Multiple warnings or errors should be grouped under a
single error banner. If multiple, unrelated, errors or warnings occur, only one banner may be
displayed at a time, display any subsequent banners after resolving the current one.`}</p>
        <h2 id="android-examples">Examples</h2>
        <h3 {...{
          "id": "single-error-1"
        }}>{`Single Error`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4c2cd0a23dddfb9cb423f5158d0a97db/536c7/banner-single-error-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "48.10810810810811%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABxElEQVR42o3SX2/SUBjH8b0S34Cvwxfgpe/GxDsSL4xeuJgYo4ZtBodeGB2rDLpMMIPBgOkGDMq/QVvaQlHmKO3XhyZcLMTNJr8857Q5n/Ock64NTItF+sYQw3KoNzRyR0VyheMw+WKJH6fVsIZzSbFUod5soQ9tluuXWVsOFlire8H2dpz9fZXDwxz5fJ5MJkMkEiGbzVIoFMIajUaJbmysYNdA0x5Rle5UVQV8giBg8fi+T7/fD+eLXM1mTC8v2VUUun19pctrYL2pkUqp/Jp6eNPfBJ4nmcF8jn/1J3w3cWxmUpPK19vBmtbmILGD//oF1kkZ7aRCu1xi1G6BO4KRQyBh4qImEnRuBeWi1V0FN/cdo3qG2aij186wm+eMOxpOq8Gk1wnxtGz8X+DB3h5ur4vTOCewpSNrxFziy3d/aDEXAHcqHSo3g4Ys0uTI6/GPvC8UiabSOHqNuVNlph/jmRU8S8bmT7DKpL7EBDRvAh0BOzz9EGPnKIaS3ILxKd5FEpx3BPobgtYj/N4zsDdJf34ioCXgP36bgYCDTo8H66+493aLh3FBNx9T+fScsSto6T6Beofg210wXqImFx0aK+BfpdLRp3uoimUAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A banner with a single error",
                "title": "A banner with a single error",
                "src": "/static/4c2cd0a23dddfb9cb423f5158d0a97db/50383/banner-single-error-android.png",
                "srcSet": ["/static/4c2cd0a23dddfb9cb423f5158d0a97db/1efb2/banner-single-error-android.png 370w", "/static/4c2cd0a23dddfb9cb423f5158d0a97db/50383/banner-single-error-android.png 740w", "/static/4c2cd0a23dddfb9cb423f5158d0a97db/536c7/banner-single-error-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When only a single error needs to be communicated to the user.`}</p>
        <h3 {...{
          "id": "single-warning-1"
        }}>{`Single Warning`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c6fc93c9d513f8f85354c3fb2ce9a6e4/536c7/banner-single-warning-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "48.10810810810811%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABy0lEQVR42o2S3W4SQRiGeyXegNfhBXjo3Zh4RuKJxsSeGBOVlkjAmDRthdCyQCHyjxVbirhb/nYXdpEFgQV2H2c3ckAa0UnefPNN8j3zzpvZUwcGnvr6EN0Y0WzJFEoVCuWqr2KlRv2q4Ve/F6rULmn+UNCGJpv5jfY2Gw+mdHpEIlFSKYl8vkCxWCSbzRIIBMjlcpTLZb8Gg0GCBwd3YFvAgfmThnAnSRLg4Lou3nIcl35f9XtP9tJmNpsTi8fp9LU7LreA3jOSUpKpvWRlT3CdudAC3BXOauafTa0hS9sikYgJoL4b2GgpXKRPwUxi9qp0lQo39Qwj7RusVVj2cJd9cYGGdH5E+1/Am9YtqeQJv3oZ9O4XDO0KQ637sobXmFqdxUQWOagkz/4LqJDOfBLD39GUKp1WCaVZZG61wdZYz7s4C8+hIRweC+CODHVzhCzfsv8xSqSe5106hqpVmI3KLMzPrMdlVpOvLMc1mJY4j4UEcCCAO76NLLd5/iHMaSlMPBEC65pV7wxG70Vsb3DlJzidFyLjQ5LHzwTQ+DtQFUC13eXR/msevA3xOCqgh0+5PHrJ2EpA7SGudA/34j7or5AS4T8ZbgN/A1IA0cgWn8pAAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A banner with a single warning",
                "title": "A banner with a single warning",
                "src": "/static/c6fc93c9d513f8f85354c3fb2ce9a6e4/50383/banner-single-warning-android.png",
                "srcSet": ["/static/c6fc93c9d513f8f85354c3fb2ce9a6e4/1efb2/banner-single-warning-android.png 370w", "/static/c6fc93c9d513f8f85354c3fb2ce9a6e4/50383/banner-single-warning-android.png 740w", "/static/c6fc93c9d513f8f85354c3fb2ce9a6e4/536c7/banner-single-warning-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When only a single warning needs to be communicated to the user.`}</p>
        <h3 {...{
          "id": "multi-error-1"
        }}>{`Multi-Error`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/401f126bc89d34bbab58209c824c88df/536c7/banner-multi-error-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "82.43243243243244%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB80lEQVR42pWTW5OaQBCF9///jTylUpUnn1K7C272JUZUNG4W5LLxBggiF02JwJx0j4vJS3Zxqk71FEx/NGe6b/xwi0ZBFGPpBVAfvuJOUXGvdHGvdvHl9k6K9/xMoag+PMqznPMv46bZeJsI2yTDUJ+gq6pYr5YIfB9ZmkJVFKk8y+B7Hgaahk6nA30yRbRLZe5/gf2hjmfDQLOKooAX+NiEIaqyujx3HBffByOZ8zZwNIap9YEwQH3Yo0gTxFRt4q1RJDuUeUZfOcKeTtF/F0jla9OfMD9/QvHxA3arFWLbwp6A++UCya8X/F6vgH0OZzRqAeQK6ZA1HKCMQgSmgWwxB3YxRLz9Gwno6i2AEb3UyMOn3jdEjg2PgEW4gSBQTaBGdDtw9CsqtGczaXx1OknVZYm6qmQ8HY+AELBMsz2Qb5AX3+x8sYBl27AsC7bjII5j+c513WuAjkw6HA6y99IkQUYxz3McucJrgOyhQX0YRXQooEkINlTpBlV17sEmcrXtKiTgrPGQklnSv7q+qCIZ5CF/vAWQ2sY+/3JJ5nM9FxGopIvhxZ723wPyXGo0y09jHRk1c03tIajnRH4WzR7E6+i185CB4x8wej2k5jOE70HQyMlIE1LTxVwN5Fm2JhNgOUfJenFQ+wTlaslLIcSbwD89cKTRWkvEJwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A banner with multiple errors",
                "title": "A banner with multiple errors",
                "src": "/static/401f126bc89d34bbab58209c824c88df/50383/banner-multi-error-android.png",
                "srcSet": ["/static/401f126bc89d34bbab58209c824c88df/1efb2/banner-multi-error-android.png 370w", "/static/401f126bc89d34bbab58209c824c88df/50383/banner-multi-error-android.png 740w", "/static/401f126bc89d34bbab58209c824c88df/536c7/banner-multi-error-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When multiple errors need to be communicated to the user. When errors and warnings exist together
they should be combined into this variation. The collapsed state is the default state when these
multi-error banners first appear.`}</p>
        <h3 {...{
          "id": "multi-error-expanded-1"
        }}>{`Multi-Error Expanded`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ed72d510c9dacf55b1ce1833973f3f51/536c7/banner-multi-error-expanded-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "82.43243243243244%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB5klEQVR42pWU227TQBCG+/6vwQ0SEle5QrR2ChfQxEncNim2G29CnPh8dg6uDz+zC0EtCGqv9GtW493PM+MZXzh+iLPcIIJlu5CvP+FSknElDXElD/Hh46UQ33OfRFa+/izO8jvPGRfnje0FCJMMU/UOQ1nGbmvBdRxkaQpZkoTyLINj25goCgaDAdS7OYI4FXf/CRxPVXzTNJxXWZawXQee76Ou6t9+02QYTWbizv+Bs1voyhjwXTT7AmWaIKJoE3uHMolR5Rm95YTlfI7xq0AKX5k/QH//Dk9v3yDebhE+GigIWFgbJOsVDrstUOQwZ7MOQB4hHXqcTlB6VHDDQE4wxBHaKET7y3IgUzsCFaqhNh4hWC7hU3QcgmcSQCoFU9XuQF7DhkeUpQDV7QWQW6orz+JVYEAPJzMVi69fEDETe6pX5XsvoL2BPMKHmxuEbInv9/fINhsB+Cvl2x4pa6MRPENHZnFYjCbw0YaBUEOiDgfr85U1iqykS0eakuZ4REt9J3Q6oaLoqNthLBbdgXwK+IqTBBb1oskYzNUKq/Va+Phi5OsOZD+BeZ4jo/lNCBKGoVBBvl5A0Ta6LkBRFAlYylNvGgFq27Y/UKOfw/5wQEKgs56qClVdoyJbE1ynKeJn/wT+ADFmoSZlH1K9AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "An expanded banner with multiple errors",
                "title": "An expanded banner with multiple errors",
                "src": "/static/ed72d510c9dacf55b1ce1833973f3f51/50383/banner-multi-error-expanded-android.png",
                "srcSet": ["/static/ed72d510c9dacf55b1ce1833973f3f51/1efb2/banner-multi-error-expanded-android.png 370w", "/static/ed72d510c9dacf55b1ce1833973f3f51/50383/banner-multi-error-expanded-android.png 740w", "/static/ed72d510c9dacf55b1ce1833973f3f51/536c7/banner-multi-error-expanded-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When a user taps on the “Show Errors” button the lower portion expands revealing, a space limited
view of, all the errors/warnings. A counter is provided to communicate the total amount of errors
preceded by the number of errors visible within the banner. Tapping on any input label within the
banner should automatically scroll the page to the input in question. When errors are resolved by
the user, the labels are dynamically removed from the section, with the Banner closing once all
errors have been resolved.`}</p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun BannerUiComponent(
    modifier: Modifier = Modifier,
    bannerType: BannerType,
    title: String,
    message: String? = null,
    primaryButton: BannerButtonItem? = null,
    secondaryButton: BannerButtonItem? = null,
    errorList: List<BannerButtonItem>? = null,
    shouldShowBanner: Boolean = true
) {
`}</code></pre>
        <h3 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`modifier`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Modifier to be applied to the `}<inlineCode parentName="td">{`BannerUiComponent`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`bannerType`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Config that defines what type of banner to show ex: Warning, Error or MultiError`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`title`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The title of the task we are using this banner for`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`message`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This should describe the task's description. Message should be short and precise preferably up to two lines.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`primaryButton`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of the Button that provides interaction for the user to act on. If only one Button is displayed, it should be this one.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`secondaryButton`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of the Button that provides interaction for the user to act on`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`errorList`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The list that will be displayed in the Multi Error configuration, Only the first 3 errors will be displayed and the rest are hidden and shown as we click on the errors and resolve them.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`shouldShowBanner`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This determines if the banner is shown/hidden. Instead of removing the `}<inlineCode parentName="td">{`BannerUiComponent`}</inlineCode>{` node from the composition, if this boolean is updated to false, banner will be hidden with an animation.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Coming Soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4e54d3e528884560997979a4fba7c6f0/536c7/banner-content.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.45945945945946%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABjElEQVR42o1T2VLCQBDk///J8s0qRQEVVOTIvckeswkBRBK1nV2wSnmQPHTtbGqmM9M92ys04S80VLmCsRa2JIb10Ia/n6DQBqf1vd+XXBlQRfiQV9joMfJkDJk+QkT32G8SoC2A5oCvvQBZyTX/EnJHTLiNLkHRNYQjTB6QBgNUeoo1zbAyr1jbOdptjNKqDoT819oGMMUUtngGMax8gRYTvK9DNJsYDZN97lKfe5awLBVUMoIIuSv1gnjeR7K4RR6NAB4Tbc4j87nPmLD4n9BBaum1IRKoyhyWuJDj0ubHu4sPkFqdM4VA7GyjbrGlKUq9RE1L1m8Okk67AO1OoH1zyLqYYrwp78kFqqyPeDFEuhx4U8LZjR/b6edHbrKOGnKSkTME8wGMeILKxoyJN8atj4ud087ls4TiaEqlnqF4/3argDtKsK2W2NUh3urAx/t15Lvs1KFLouIJMY+ahSPWcIY0HPrukmCIaHHHmr56x4k6uFwoflbstCbtoYw6PrvD+RO7bXC5py5/A/GIf7qDRCTiAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Warning banners in multiple languages",
                "title": "Warning banners in multiple languages",
                "src": "/static/4e54d3e528884560997979a4fba7c6f0/50383/banner-content.png",
                "srcSet": ["/static/4e54d3e528884560997979a4fba7c6f0/1efb2/banner-content.png 370w", "/static/4e54d3e528884560997979a4fba7c6f0/50383/banner-content.png 740w", "/static/4e54d3e528884560997979a4fba7c6f0/536c7/banner-content.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ul>
          <li parentName="ul">{`Messages in banners should be short and concise, preferably up to 2 lines. Additional lines may be
needed to support other languages with longer characters.`}</li>
          <li parentName="ul">{`In titles, avoid apologies (i.e., sorry, please, etc…), or additional written level of emphasis
(e.g., warning).`}</li>
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/error-alert-messages"
            }}>{`Error and Alert Messages`}</a>{` in the UI Text Section of
Content Style Guide for additional language guidelines.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      